
import QInput from 'quasar/src/components/input/QInput.js';import useFormChild from 'quasar/src/composables/use-form-child.js';;
import { computed, defineComponent, PropType, ref, Ref } from "vue";

import { isTimeStringValidFormat } from "@/lib/dateTime";

import { useInputProps } from "./useInputProps";

export default defineComponent({
  props: {
    ...useInputProps(),
    modelValue: {
      type: String as unknown as PropType<string | null>,
      required: false,
      default: null,
    },
  },
  emits: {
    "update:modelValue": (v: string | null) =>
      v === null || typeof v === "string",
  },
  setup(props) {
    const inputRef: Ref<QInput | null> = ref(null);

    useFormChild({
      validate: () => inputRef.value?.validate?.() ?? false,
      resetValidation: () => inputRef.value?.resetValidation?.(),
      requiresQForm: false,
    });

    const rules = computed(() => {
      const rulesWithExtra = Array.from(props.extraRules ?? []);
      rulesWithExtra.push(
        (v: string | null) =>
          isTimeStringValidFormat(v) || "This is not a valid time format"
      );
      return rulesWithExtra;
    });
    return {
      rules,
      inputRef,
    };
  },
});
