import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, createElementVNode as _createElementVNode, withCtx as _withCtx, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row items-center justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_time = _resolveComponent("q-time")!
  const _component_q_popup_proxy = _resolveComponent("q-popup-proxy")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_input = _resolveComponent("q-input")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createBlock(_component_q_input, _mergeProps({
    ref: "inputRef",
    dense: _ctx.dense,
    label: _ctx.label,
    hint: _ctx.hint,
    disable: _ctx.disable,
    "model-value": _ctx.modelValue,
    rules: _ctx.rules,
    clearable: "",
    "data-test": "timeinput.input",
    filled: "",
    mask: "time"
  }, _ctx.$attrs, {
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (
      _ctx.$emit('update:modelValue', $event ? String($event) : null)
    ))
  }), {
    append: _withCtx(() => [
      _createVNode(_component_q_icon, {
        name: "access_time",
        class: "cursor-pointer"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_popup_proxy, {
            "transition-show": "scale",
            "transition-hide": "scale"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_time, {
                "model-value": _ctx.modelValue,
                format24h: "",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event)))
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    _withDirectives(_createVNode(_component_q_btn, {
                      label: "Close",
                      color: "primary",
                      flat: ""
                    }, null, 512), [
                      [_directive_close_popup]
                    ])
                  ])
                ]),
                _: 1
              }, 8, ["model-value"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 16, ["dense", "label", "hint", "disable", "model-value", "rules"]))
}