import * as t from "io-ts";

export const StatusGuard = t.keyof({
  Draft: null,
  Deleted: null,
  Pending: null,
  Active: null,
  Paused: null,
  Cancelled: null,
  Failed: null,
  Finished: null,
});

export type Status = t.TypeOf<typeof StatusGuard>;

const FALLBACK_STATUS_COLOR = "grey";

const STATUS_TO_COLOR_MAP: {
  [key in Status]: string;
} = {
  Draft: "teal-5",
  Deleted: "grey-7",
  Pending: "info",
  Active: "primary",
  Paused: "teal-8",
  Cancelled: "grey-6",
  Failed: "negative",
  Finished: "positive",
};

export function getStatusColor(status: string) {
  return STATUS_TO_COLOR_MAP[status as Status] ?? FALLBACK_STATUS_COLOR;
}
