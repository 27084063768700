import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed, ref, watch } from "vue";

import { Tag } from "@/api/tags/typedefs";

interface Props {
  // Either modelValue or selectedTagId will be used to feed <q-select>
  modelValue?: Tag[] | null;
  selectedTagId?: number | null;
  label: string;
  hint?: string;
  fetchOptions: () => Promise<Tag[]>;
  disable?: boolean;
  singleSelectMode?: boolean;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'TagSelect',
  props: {
    modelValue: { default: null },
    selectedTagId: { default: null },
    label: {},
    hint: { default: "" },
    fetchOptions: {},
    disable: { type: Boolean, default: false },
    singleSelectMode: { type: Boolean, default: false }
  },
  emits: ["update:model-value"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const options = ref<Tag[]>([]);
const isLoading = ref<boolean>(false);

const fetchTags = async () => {
  isLoading.value = true;
  try {
    options.value = await props.fetchOptions();
  } catch {
    //ignore
  } finally {
    isLoading.value = false;
  }
};

const tagById = computed(() => {
  for (const tag of options.value) {
    if (tag.id === props.selectedTagId) {
      return tag;
    }
  }
  return null;
});

watch(
  props,
  () => {
    if (props.modelValue && props.selectedTagId) {
      throw new Error(
        "You can't set both modelValue and selectedTagId at the same time"
      );
    }
  },
  { immediate: true }
);

fetchTags();

return (_ctx: any,_cache: any) => {
  const _component_q_select = _resolveComponent("q-select")!

  return (_openBlock(), _createBlock(_component_q_select, {
    "model-value": props.modelValue || tagById.value,
    disable: props.disable,
    loading: isLoading.value,
    filled: "",
    dense: props.singleSelectMode,
    clearable: props.singleSelectMode,
    multiple: !props.singleSelectMode,
    options: options.value,
    "use-chips": !props.singleSelectMode,
    "stack-label": !props.singleSelectMode,
    label: props.label,
    hint: props.hint,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (emit('update:model-value', $event)))
  }, null, 8, ["model-value", "disable", "loading", "dense", "clearable", "multiple", "options", "use-chips", "stack-label", "label", "hint"]))
}
}

})