import { httpClient } from "@/lib/http";
import { cast } from "@/lib/typing";

import { Tag, TagGuard } from "./typedefs";

const TAGS_LIST_URL = "/api/tags/";

export function convertTags(data: unknown): Tag[] {
  if (!Array.isArray(data)) {
    return [];
  }
  return data.map((item) => cast(TagGuard, item));
}

export async function fetchTags(): Promise<Tag[]> {
  const response = await httpClient.get(TAGS_LIST_URL);
  const json = await response.json();
  return convertTags(json);
}
